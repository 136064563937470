html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", "Roboto Condensed", sans-serif;
  height: 100%;
  min-height: 100%;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4f4f4f;
  /* background: linear-gradient(to right, #7109fc, #9215e7, #f739aa); */
  border-radius: 10px;
}

.table td,
.table th {
  border-top: 0px !important;
}

.carousel-banner {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.image-behind {
  z-index: -1;
  position: absolute;
}

/* React tag input styling !important for override*/
.react-tagsinput-tag {
  background-color: #fff !important;
  border-radius: 15px !important;
  border: 1px solid #828282 !important;
  color: #828282 !important;
  display: inline-block;
  font-family: "Montserrat" !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.react-tagsinput {
  background-color: #fff;
  border: 1px solid #b2b2b2 !important;
  border-radius: 5px !important;
  overflow: hidden !important;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput-input {
  color: #828282 !important;
  font-family: "Montserrat" !important;
  font-weight: 500;
  font-size: 14px !important;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 110px !important;
}

/* //Custom scrollbar for 1280px-1400px */
@media screen and (min-width: 1280px) {
  .stream-scroll {
    height: calc(100% - 14.5%);
    opacity: 1;
  }
}

@media screen and (min-width: 1280px) {
  .stream-scroll .rcs-custom-scroll {
    height: 100% !important;
    border-radius: 3px;
    opacity: 1;
  }
}

/* Controls Scroll Bar Itself */
@media screen and (min-width: 1280px) {
  .stream-scroll .rcs-inner-handle {
    height: 79.5% !important;
    margin-top: 0px !important;
    background-color: #4f4f4f !important;
    border-radius: 3px;
    opacity: 1;
    z-index: 0;
  }
}

@media screen and (min-width: 1280px) {
  .stream-scroll .rcs-custom-scrollbar {
    height: 625px !important;
    margin-top: 60px !important;
    background-color: #e0e0e0 !important;
    border-radius: 3px;
    opacity: 1;
  }
}

/* //Custom scrollbar for 1280px-1400px */
@media screen and (min-width: 1280px) {
  .stream-scroll {
    height: calc(100% - 14.5%);
    opacity: 1;
  }
}

@media screen and (min-width: 1600px) {
  .stream-scroll .rcs-custom-scroll {
    height: 100% !important;
    border-radius: 3px;
    opacity: 1;
  }
}

/* Controls Scroll Bar Itself */
@media screen and (min-width: 1600px) {
  .stream-scroll .rcs-inner-handle {
    height: 86% !important;
    margin-top: 0px !important;
    background-color: #4f4f4f !important;
    border-radius: 3px;
    opacity: 1;
    z-index: 0;
  }
}

@media screen and (min-width: 1600px) {
  .stream-scroll .rcs-custom-scrollbar {
    height: 630px !important;
    margin-top: 55px !important;
    background-color: #e0e0e0 !important;
    border-radius: 3px;
    opacity: 1;
  }
}

.stream-scroll .rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

/* //Custom scrollbar for 1280px-1400px */
@media screen and (min-width: 1280px) {
  .content-scroll {
    height: calc(100% - 12.5%);
    opacity: 1;
  }
}

@media screen and (min-width: 1280px) {
  .content-scroll .rcs-custom-scroll {
    height: 100% !important;
    border-radius: 3px;
    opacity: 1;
  }
}

/* Controls Scroll Bar Itself */
@media screen and (min-width: 1280px) {
  .content-scroll .rcs-inner-handle {
    margin-top: 0px !important;
    background-color: #4f4f4f !important;
    border-radius: 3px;
    opacity: 1;
    z-index: 0;
  }
}

@media screen and (min-width: 1280px) {
  .content-scroll .rcs-custom-scrollbar {
    height: 490px !important;
    background-color: #e0e0e0 !important;
    border-radius: 3px;
    opacity: 1;
  }
}

/* Image Picker */

.image_picker {
  width: 100%;
}
.image_picker .responsive {
  position: relative;
  margin: 0 6px;
  float: left;
  opacity: 1;
}
.image_picker .responsive .thumbnail {
  border: 1px solid #ddd;
  /* Gray border */
  border-radius: 4px;
  /* Rounded border */
  padding: 5px;
  /* Some padding */
  width: 150px;
  /* Set a small width */
  opacity: 1;
  margin: 0;
}
.image_picker .responsive .thumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
}
.image_picker .responsive .selected {
  background-color: rgba(0, 140, 186, 1);
}
.image_picker .responsive:hover {
  cursor: pointer;
}
.image_picker .responsive .checked {
  display: none;
}
.image_picker .selected {
  opacity: 0.7;
}
.image_picker .selected .checked {
  display: inline-block;
  transition: 0.5s ease;
  position: absolute;
  bottom: 0;
  right: 0;
}
.image_picker .selected .checked .icon {
  background: url("../public/images/check.png");
  background-size: cover;
  height: 75px;
  width: 75px;
}
.image_picker .clear {
  clear: both;
}

.image-container {
  width: 50vw;
  height: 50vh;
}
.image-container .image {
  width: 100%;
  height: 100%;
  position: relative !important;
  object-fit: contain;
}

.Example input,
.Example button {
  font: inherit;
}
.Example header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}
.Example header h1 {
  font-size: inherit;
  margin: 0;
}
.Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  /* height: 100% !important; */
}
/* .Example__container__load {
  margin-top: 1em;
  color: white;
} */
.Example__container__document {
  margin: 1em 0;
}
.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Example__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}
/* .Example__container__document .react-pdf__Page canvas {
  max-width: 100%;

  height: auto !important;
} */
/* .Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
} */

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.creator_blade_active {
  color: #00fb4b !important;
}

.brand_blade_active {
  color: #815fd3 !important;
}

.contact_us_toast_success {
  color: #fff !important;
  background: #815fd3 !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  /* width: 500px !important; */
}

.contact_us_toast_success .Toastify__close-button {
  color: #fff !important;
}

.contact_us_toast_success .Toastify__progress-bar {
  background: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #815fd3,
    #ff2d55
  ) !important;
  /* background: #00fb4b !important; */
  /* background: #fff !important; */
}

.signup_demo_success {
  color: #fff !important;
  background: #815fd3 !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  /* text-transform: capitalize !important; */
}

.signup_demo_success .Toastify__close-button {
  color: #fff !important;
}

.signup_demo_success .Toastify__progress-bar {
  background: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #815fd3,
    #ff2d55
  ) !important;
}

.validator.validator-email {
  width: 100% !important;
}

.validator.validator-phone {
  width: 100% !important;
}

.validator select {
  display: none !important;
}

.validator.validator-email.validation-no .feedback {
  display: none !important;
}

.validator.validator-email.validation-none .feedback {
  display: none !important;
}

.validator.validator-phone.validation-none .feedback {
  display: none !important;
}
.validator.validator-email.validation-yes .feedback {
  display: none !important;
}

.validator.validator-email.validation-yes .feedback {
  display: none !important;
}

.validator.validator-phone.validation-yes .feedback {
  display: none !important;
}

.validator.validator-phone.validation-no .feedback {
  display: none !important;
}
.validator.loading .feedback {
  display: none !important;
}

.validator select {
  display: none !important;
}

/* Used to hide ghost div that shows up when instagram social linking button is clicked
  Couldn't figure out why the empty div was being generated, so hiding it instead
*/
.instagram-link-button .css-0 {
  display: none !important;
}

.infinite-scroll-component {
  padding-right: 10px !important;
}
.infinite-scroll-component::-webkit-scrollbar {
  width: 12px !important;
}
/* Track / */
.infinite-scroll-component::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
/* / Handle / */
.infinite-scroll-component::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0px !important;
}
/* / Handle on hover */
.infinite-scroll-component::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
